import { exWorkOrder } from "@/api/order"
import { MessageBox } from 'element-ui'

export default (context) => {
  return {
    columns: [
      {
        prop: "businessNo",
        label: "业务编号",
        width: 10,
      },
      {
        prop: "instrumentName",
        label: "仪器名称",
        width: 15,
      },
      {
        prop: "factoryNo",
        label: "出厂编号",
        width: 10,
      },
      {
        prop: "specificationsType",
        label: "型号规格",
        width: 10,
      },
      {
        prop: "client",
        label: "委托单位",
        width: 20,
      },
      {
        prop: "workTime",
        label: "实验室领样日期",
        width: 10,
      },
    ],
    operate: [
      {
        class: "table-btn primary-button", //操作按钮显示类型
        label: "检测人领样", //按钮显示文字
        handle: (e) => {
          exWorkOrder({ id: e.id, workStatus: 1 }).then(res => {
            MessageBox.confirm("已领样该条工单,是否开始检测?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "success",
            }).then(() => {
              context.$router.push({
                name: "orderAcceptance",
                params: { id: e.id, instrumentName: encodeURI(e.instrumentName) },
              });
            }).catch(() => {
              context.getOrderList();
            });
          }).catch(e => {
            console.error(e)
          })

        },
      },
    ],
    operateWith: 10,
  };
}